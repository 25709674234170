import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'


function MobileMenu() {

    const [isActive, setActive] = useState(false)

    const toggleMenu = () => {
        setActive(!isActive)
    }
    console.log(isActive)
	return (
        <div class="mobilemenu">
            <div class="hamburger">
                <input onClick={toggleMenu} class="hamburger__checkbox" type="checkbox" name="" id="" />
                <div class="hamburger__lines">
                    <span class="line line__line1"className={`line line__line1 ${isActive ? "line--line1" : ""}`}></span>
                    <span class="line line__line2"className={`line line__line1 ${isActive ? "line--line2" : ""}`}></span>
                    <span class="line line__line3"className={`line line__line1 ${isActive ? "line--line3" : ""}`}></span>
                </div>  
            <div className={`mobilemenu__items ${isActive ? "activeMenu" : ""}`}>
                <li>
                    <Link onClick={toggleMenu} exact='true' className='menu-link' to='/'>
                        <FontAwesomeIcon icon={faHome} color='$sidebarIcon' /> Домой
                    </Link>
                </li>
                <li>
                    <Link onClick={toggleMenu} exact='true' className='menu-link' to='about'>
                        <FontAwesomeIcon icon={faUser} color='$sidebarIcon' /> Обо мне
                    </Link>
                </li>
                <li>
                    <Link onClick={toggleMenu} exact='true' className='menu-link' to='about' >
                        <FontAwesomeIcon icon={faEnvelope} color='$sidebarIcon' /> Контакты
                    </Link>
                </li>
            </div>
            </div>
        </div>
    );
}
      
export default MobileMenu;


