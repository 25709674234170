import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './sass/index.sass';
import App from './App';
import Sidebar from './components/sidebar/sidebar';
import MobileMenu from './components/sidebar/mobileMenu';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<MobileMenu />
			<Sidebar />
			<App />
		</BrowserRouter>
	</React.StrictMode>
);